<template>
  <div id="footer" class="footer">
    © NomadSystemWorks. All right reserved.
  </div>
</template>

<script>
export default {
  name: 'FooterItem',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  background: gray;
  width: 100%;
  font-size:13px!important;
}
</style>
