<template>
  <div class="content top">
    <div class="inner-grid">
      <div class="inner-grid-eyecatch">
        <div class="inner-grid-eyecatch-wrapper">
          <div class="animate1 logotext">
            <span>I</span><span>T</span><span>を</span><span>も</span><span>っ</span><span>と</span><span>身</span><span>近</span><span>に</span>
          </div>
          <div class="animate2 logotext">
            <span>シ</span><span>ス</span><span>テ</span><span>ム</span><span>開発</span><span>・</span><span>W</span><span>E</span><span>B</span><span>構</span><span>築</span><span>・</span><span>I</span><span>T</span><span>導</span><span>入</span><span>支</span><span>援</span>
          </div>
          <div class="animate3 logotext">
            <span>お</span><span>困</span><span>り</span><span>ご</span><span>と</span><span>あ</span><span>れ</span><span>ば</span><span>ご</span><span>相</span><span>談</span><span>く</span><span>だ</span><span>さ</span><span>い</span>
          </div>
        </div>
      </div>
      <!--
      <div class="inner-grid-newblog">
        <h3>最新ブログ</h3>
        <div class="blog-marquee">
          <div class="blog-container" id="blog-area">
            
          </div>
        </div>
      </div>
      -->
      <div class="inner-grid-work">
        <h3>事業内容</h3>
        <div class="inner-grid-work-container">
          <div class="inner-grid-work-container-title1 work-title"><p>業務分析・改善</p></div>
          <div class="inner-grid-work-container-content1"><p>会社の現状を分析し、AsIs-ToBeを表現しシステム化の支援を行います。</p></div>
          <div class="inner-grid-work-container-title2 work-title"><p>システム開発</p></div>
          <div class="inner-grid-work-container-content2"><p>AWS等のサービスを使用してインフラ構築からの一貫したWEBシステム開発や業務に必要なツールを製作し業務の支援を行います。</p></div>
          <div class="inner-grid-work-container-title3 work-title"><p>WEBサイト構築</p></div>
          <div class="inner-grid-work-container-content3"><p>WordPressの構築やコーポレートサイトの構築を行います。</p></div>
          <div class="inner-grid-work-container-title4 work-title"><p>運用保守</p></div>
          <div class="inner-grid-work-container-content4"><p>既存システムのメンテやシステム改修を行い業務の支援を行います。</p></div>
          <div class="inner-grid-work-container-common-content"><p>詳細はお問い合わせください。</p></div>
        </div>
      </div>
      <div class="inner-grid-service">
        <h3>サービス</h3>
        <ul>
          <li>製作中...</li><br><br><br>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import jquery from 'jquery'
//import axios from 'axios'

export default {
  name: 'TopItem',
  mounted: function(){
    jquery('.repeat').click(function(){
        var classes =  jquery(this).parent().attr('class');
        jquery(this).parent().attr('class', 'animate');
        var indicator = jquery(this);
        setTimeout(function(){ 
          jquery(indicator).parent().addClass(classes);
        }, 20);
      });
    
    /*
    axios
      .get('https://blog.nomadsystem.works/wp-json/wp/v2/posts?per_page=4&_embed')
      .then((response) => {
        for(var i = 0; i < response.data.length; i++) {
          var divImg = jquery('<div></div>').addClass('blog-card-img');
          var img = jquery('<img></img>').attr('src', response.data[i]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['thumbnail'].source_url).attr('alt', 'logo');
          divImg.append(img);

          var divTitle = jquery('<div></div>').addClass('blog-card-title');
          var title = response.data[i].title.rendered;
          divTitle.text(title);

          var a = jquery('<a></a>').attr('href', response.data[i].link);
          a.append(divImg).append(divTitle);

          var divContainer = jquery('<div></div>').addClass('blog-card-container' + (i + 1)).append(a);

          jquery('#blog-area').append(divContainer);
        }
      });
    */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 1000px){
  .inner-grid {
    grid-template-columns: 1fr!important;
    grid-template-rows: auto!important;
    grid-template-areas:
        "eyecatch"
        "newblog"
        "work"
        "service"!important;
  }

  .inner-grid-eyecatch {
    position: static!important;
  }

  .img-wrap {
    padding-top: 20px;
    position: static!important;
  }

  .animate1 {
    font-size: 50px;
    position: static!important;
    padding-top: 120px;
  }
  .animate2 {
    font-size: 20px;
    position: static!important;
  }
  .animate3 {
    font-size: 20px;
    position: static!important;
    padding-bottom: 120px;
  }

  .blog-marquee {
    display: inline-flex;
  }

  .img-wrap-fadein-top-left {
    animation-name: fadein-mobile!important;
    animation-duration: 4.0s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .img-wrap-fadein-top-right {
    display: none;
  }

  .img-wrap-fadein-bottom-left {
    display: none;
  }

  .img-wrap-fadein-bottom-right {
    display: none;
  }

  @keyframes fadein-mobile {
    0% {
      transform: translate(0px, 0px);
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  .inner-grid-work-container {
    display: grid;
    grid-template-columns: 1fr!important;
    grid-template-rows: auto;
    row-gap:10px!important;
    text-align: left;
    grid-template-areas:
          "title1"
          "content1"
          "title2"
          "content2"
          "title3"
          "content3"
          "title4"
          "content4"
          "common-content"!important;
  }

  .work-title {
    padding-top:20px;
    text-align: center;
  }
}

.inner-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 400px auto;
  grid-template-areas:
        "eyecatch eyecatch eyecatch eyecatch eyecatch eyecatch"
        "newblog newblog newblog newblog newblog newblog"
        "work work work work work work"
        "service service service service service service";
}

.img-wrap {
  overflow: hidden;
  position: absolute;
  left: calc(50% - 340px);
  top:60px;
}

.img-wrap img {
  width: 250px;
}

.animate1 {
  font-size: 50px;
  left: calc(50% - 10px);
  top: 120px;  
}
.animate2 {
  font-size: 20px;
  left: calc(50% + 55px);
  top: 200px;  
}
.animate3 {
  font-size: 20px;
  left: calc(50% + 245px);
  top: 230px;  
}

.inner-grid-eyecatch {
  grid-area: eyecatch;
  /*background-color: rgba(32,47,91,0.4);*/
  /*background-color: #c6ffee;*/
  width: 100%;
  background: url(@/assets/top_eyecatch.jpeg),linear-gradient(135deg, #000, #000)fixed,no-repeat;
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  position: relative;
}

.no-webp .inner-grid-eyecatch {
  grid-area: eyecatch;
  /*background-color: rgba(32,47,91,0.4);*/
  /*background-color: #c6ffee;*/
  width: 100%;
  background: url(@/assets/top_eyecatch.jpeg),linear-gradient(135deg, #000, #000)fixed,no-repeat;
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  position: relative;
}

.inner-grid-eyecatch-wrapper {
  margin: 0 auto;
  color: white;
  text-shadow: rgba(44, 62, 78, 0.7) 1px 1px 10px, rgba(44, 62, 78, 0.7) -1px 1px 10px, rgba(44, 62, 78, 0.7) 1px -1px 10px, rgba(44, 62, 78, 0.7) -1px -1px 10px;
}

.img-wrap-fadein-top-left {
  animation-name: fadein-top-left;
  animation-duration: 4.0s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.img-wrap-fadein-top-right {
  animation-name: fadein-top-right;
  animation-duration: 4.0s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.img-wrap-fadein-bottom-left {
  animation-name: fadein-bottom-left;
  animation-duration: 4.0s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.img-wrap-fadein-bottom-right {
  animation-name: fadein-bottom-right;
  animation-duration: 4.0s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadein-top-left {
  0% {
    transform: translate(200px,-150px);
    opacity: 0;
  }
  100% {
    transform: translate(0,0);
  }
  20%,100% {
    opacity: 1;
  }
}

@keyframes fadein-top-right {
  0% {
    transform: translate(-200px,-150px);
    opacity: 0;
  }
  100% {
    transform: translate(0,0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes fadein-bottom-left {
  0% {
    transform: translate(200px,150px);
    opacity: 0;
  }
  100% {
    transform: translate(0,0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes fadein-bottom-right {
  0% {
    transform: translate(-200px,150px);
    opacity: 0;
  }
  100% {
    transform: translate(0,0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.inner-grid-service {
  grid-area: service;
}

.inner-grid-work {
  grid-area: work;
  background-color: rgb(255, 255, 255);
}

.inner-grid-work-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap:30px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 720px;
  text-align: left;
  grid-template-areas:
        "title1 content1 content1"
        "title2 content2 content2"
        "title3 content3 content3"
        "title4 content4 content4"
        "common-content common-content common-content";
}

.inner-grid-work-container-title1 {
  grid-area: title1;
  font-weight: bold;
}

.inner-grid-work-container-title2 {
  grid-area: title2;
  font-weight: bold;
}

.inner-grid-work-container-title3 {
  grid-area: title3;
  font-weight: bold;
}

.inner-grid-work-container-title4 {
  grid-area: title4;
  font-weight: bold;
}

.inner-grid-work-container-content1 {
  grid-area: content1;
}

.inner-grid-work-container-content2 {
  grid-area: content2;
}

.inner-grid-work-container-content3 {
  grid-area: content3;
}

.inner-grid-work-container-content4 {
  grid-area: content4;
}

.inner-grid-work-container-common-content {
  grid-area: common-content;
  text-align: center;
}

.animate1 {
	font-size: 50px;
	position: absolute;
  left: calc(50% - 10px);
  top: 120px;  
}

.animate1 span {
	display: inline-block;
}

.animate1 span:nth-of-type(1) {
	animation-delay: 1.00s;
  
}
.animate1 span:nth-of-type(2) {
	animation-delay: 1.05s;
}
.animate1 span:nth-of-type(3) {
	animation-delay: 1.1s;
}
.animate1 span:nth-of-type(4) {
	animation-delay: 1.15s;
}
.animate1 span:nth-of-type(5) {
	animation-delay: 1.2s;
}
.animate1 span:nth-of-type(6) {
	animation-delay: 1.25s;
}
.animate1 span:nth-of-type(7) {
	animation-delay: 1.3s;
}
.animate1 span:nth-of-type(8) {
	animation-delay: 1.35s;
}
.animate1 span:nth-of-type(9) {
	animation-delay: 1.4s;
}
.animate1 span:nth-of-type(10) {
	animation-delay: 1.45s;
}
.animate1 span:nth-of-type(11) {
	animation-delay: 1.5s;
}
.animate1 span:nth-of-type(12) {
	animation-delay: 1.55s;
}
.animate1 span:nth-of-type(13) {
	animation-delay: 1.6s;
}
.animate1 span:nth-of-type(14) {
	animation-delay: 1.65s;
}
.animate1 span:nth-of-type(15) {
	animation-delay: .7s;
}
.animate1 span:nth-of-type(16) {
	animation-delay: 1.75s;
}
.animate1 span:nth-of-type(17) {
	animation-delay: 1.8s;
}
.animate1 span:nth-of-type(18) {
	animation-delay: 1.85s;
}
.animate1 span:nth-of-type(19) {
	animation-delay: 1.9s;
}
.animate1 span:nth-of-type(20) {
	animation-delay: 1.95s;
}

.animate2 {
	font-size: 20px;
	position: absolute;
  left: calc(50% + 55px);
  top: 200px;  
}

.animate2 span {
	display: inline-block;
}

.animate2 span:nth-of-type(1) {
	animation-delay: 2.00s;
}
.animate2 span:nth-of-type(2) {
	animation-delay: 2.05s;
}
.animate2 span:nth-of-type(3) {
	animation-delay: 2.1s;
}
.animate2 span:nth-of-type(4) {
	animation-delay: 2.15s;
}
.animate2 span:nth-of-type(5) {
	animation-delay: 2.2s;
}
.animate2 span:nth-of-type(6) {
	animation-delay: 2.25s;
}
.animate2 span:nth-of-type(7) {
	animation-delay: 2.3s;
}
.animate2 span:nth-of-type(8) {
	animation-delay: 2.35s;
}
.animate2 span:nth-of-type(9) {
	animation-delay: 2.4s;
}
.animate2 span:nth-of-type(10) {
	animation-delay: 2.45s;
}
.animate2 span:nth-of-type(11) {
	animation-delay: 2.5s;
}
.animate2 span:nth-of-type(12) {
	animation-delay: 2.55s;
}
.animate2 span:nth-of-type(13) {
	animation-delay: 2.6s;
}
.animate2 span:nth-of-type(14) {
	animation-delay: 2.65s;
}
.animate2 span:nth-of-type(15) {
	animation-delay: 2.7s;
}
.animate2 span:nth-of-type(16) {
	animation-delay: 2.75s;
}
.animate2 span:nth-of-type(17) {
	animation-delay: 2.8s;
}
.animate2 span:nth-of-type(18) {
	animation-delay: 2.85s;
}
.animate2 span:nth-of-type(19) {
	animation-delay: 2.9s;
}
.animate2 span:nth-of-type(20) {
	animation-delay: 2.95s;
}

.animate3 {
	font-size: 20px;
	position: absolute;
  left: calc(50% + 85px);
  top: 230px;  
}

.animate3 span {
	display: inline-block;
}

.animate3 span:nth-of-type(1) {
	animation-delay: 3.00s;
}
.animate3 span:nth-of-type(2) {
	animation-delay: 3.05s;
}
.animate3 span:nth-of-type(3) {
	animation-delay: 3.1s;
}
.animate3 span:nth-of-type(4) {
	animation-delay: 3.15s;
}
.animate3 span:nth-of-type(5) {
	animation-delay: 3.2s;
}
.animate3 span:nth-of-type(6) {
	animation-delay: 3.25s;
}
.animate3 span:nth-of-type(7) {
	animation-delay: 3.3s;
}
.animate3 span:nth-of-type(8) {
	animation-delay: 3.35s;
}
.animate3 span:nth-of-type(9) {
	animation-delay: 3.4s;
}
.animate3 span:nth-of-type(10) {
	animation-delay: 3.45s;
}
.animate3 span:nth-of-type(11) {
	animation-delay: 3.5s;
}
.animate3 span:nth-of-type(12) {
	animation-delay: 3.55s;
}
.animate3 span:nth-of-type(13) {
	animation-delay: 3.6s;
}
.animate3 span:nth-of-type(14) {
	animation-delay: 3.65s;
}
.animate3 span:nth-of-type(15) {
	animation-delay: 3.7s;
}
.animate3 span:nth-of-type(16) {
	animation-delay: 3.75s;
}
.animate3 span:nth-of-type(17) {
	animation-delay: 3.8s;
}
.animate3 span:nth-of-type(18) {
	animation-delay: 3.85s;
}
.animate3 span:nth-of-type(19) {
	animation-delay: 3.9s;
}
.animate3 span:nth-of-type(20) {
	animation-delay: 3.95s;
}

.logotext span {
	opacity: 0;
	-webkit-transform: translate(-300px, 0) scale(0);
  -moz-transform: translate(-300px, 0) scale(0);
  -ms-transform: translate(-300px, 0) scale(0);
  -o-transform: translate(-300px, 0) scale(0);
  transform: translate(-300px, 0) scale(0);
	animation: sideSlide 1.0s forwards;
}

@keyframes sideSlide {
	60% {
		-webkit-transform: translate(20px, 0) scale(1);
    -moz-transform: translate(20px, 0) scale(1);
    -ms-transform: translate(20px, 0) scale(1);
    -o-transform: translate(20px, 0) scale(1);
    transform: translate(200px, 0) scale(1);
		color: rgba(32,47,91,0.4);
	}

	80% {
		-webkit-transform: translate(20px, 0) scale(1);
    -moz-transform: translate(20px, 0) scale(1);
    -ms-transform: translate(20px, 0) scale(1);
    -o-transform: translate(20px, 0) scale(1);
    transform: translate(20px, 0) scale(1);
		color: rgba(32,47,91,0.4);
	}

	99% {
		-webkit-transform: translate(0) scale(1.2);
    -moz-transform: translate(0) scale(1.2);
    -ms-transform: translate(0) scale(1.2);
    -o-transform: translate(0) scale(1.2);
    transform: translate(0) scale(1.2);
		color: rgba(32,47,91,0.4);
	}

	100% {
		-webkit-transform: translate(0) scale(1);
    -moz-transform: translate(0) scale(1);
    -ms-transform: translate(0) scale(1);
    -o-transform: translate(0) scale(1);
    transform: translate(0) scale(1);
		opacity: 1;
	}
}

/* ブログコンテナ */
.inner-grid-newblog {
  overflow: hidden;
  grid-area: newblog;
}

.blog-marquee {
  flex-flow: row nowrap;
  overflow: hidden;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.blog-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
        "blog-card1 blog-card2 blog-card3 blog-card4";
  animation: blog-slide 15s -30s linear infinite;
  column-gap: 10px;
}

@keyframes blog-slide {
    0% {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

.blog-card-container1 {
  grid-area: blog-card1;
  padding: 10px 30px 10px 30px;
  width: 170px;
}

.blog-card-container2 {
  grid-area: blog-card2;
  padding: 10px 30px 10px 30px;
  width: 170px;
}

.blog-card-container3 {
  grid-area: blog-card3;
  padding: 10px 30px 10px 30px;
  width: 170px;
}

.blog-card-container4 {
  grid-area: blog-card4;
  padding: 10px 30px 10px 30px;
  width: 170px;
}

.blog-card-img {
  overflow: hidden;
}

.blog-card-img img {
  height: 130px;
}

.blog-card-title {
  height: auto;
  overflow: hidden;
}
</style>
