<template>
  <header class="header">
    <div id="header">
      <nav>
        <div class="navarea">
          <router-link to="/" class="logo"><img src="@/assets/nomad_logo_black_trans_title.png" alt="logo" width="213"></router-link>
          <div id="main-menu">
            <Navbar />
          </div>
        </div>
      </nav>
      <div id="hamburger-menu" class="hamburger-menu" style="display:none;">
        <input type="checkbox" id="sidenav-btn-check">
        <label for="sidenav-btn-check" class="sidenav-btn"><span></span></label>
        <Sidebar></Sidebar>
        <div id="sidenav-overlay" style="opacity: 1;" class="" v-on:click="uncheckSidenavBtn"></div>
      </div>
    </div>
  </header>
</template>

<script>
import Navbar from './nav/Nav.vue'
import Sidebar from './sidenav/Nav.vue'

import { onMounted } from 'vue';

export default {
  name: 'HeaderItem',
  components: {
    Navbar,
    Sidebar
  },
  setup() {
    function uncheckSidenavBtn() {
      var checkbox = document.getElementById('sidenav-btn-check');
      checkbox.checked = false;
    }

    function dispHamburgerMenu() {
      var width = window.innerWidth;

      if (width <= 1000) {
        document.getElementById('main-menu').style.display = "none";
        document.getElementById('hamburger-menu').style.display = "";
      } else {
        document.getElementById('main-menu').style.display = "";
        document.getElementById('hamburger-menu').style.display = "none";
      }
    }

    onMounted(() => {
      dispHamburgerMenu();
      window.addEventListener('resize', dispHamburgerMenu);
    })

    return {
      uncheckSidenavBtn,
      dispHamburgerMenu
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 1000px){
  .logo {
    float: none!important;
    padding-top: 0px!important;

  }
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: #ffffff85;
}

.navarea {
  width: 70%;
  margin: 0 auto;
}

.logo {
  float: left;
  padding-top: 2px;
}

.sidenav-btn {
    position: fixed;
    top: 3px;
    right: 3px;
    display: flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    z-index: 90;
    background-color: #2c3e50;
}

.sidenav-btn span,
.sidenav-btn span:before,
.sidenav-btn span:after {
    content: '';
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background-color: #ffffff;
    position: absolute;
    transition: all 0.5s;
}

.sidenav-btn span:before {
    bottom: 8px;
}

.sidenav-btn span:after {
    top: 8px;
}

#sidenav-btn-check:checked ~ .sidenav-btn span {
    background-color: rgba(255, 255, 255, 0);
}
#sidenav-btn-check:checked ~ .sidenav-btn span::before {
    bottom: 0;
    transform: rotate(45deg);
    transition: all 0.5s;
}
#sidenav-btn-check:checked ~ .sidenav-btn span::after {
    top: 0;
    transform: rotate(-45deg);
    transition: all 0.5s;
}

#sidenav-btn-check {
    display: none;
}

#sidenav-btn-check:checked ~ .sidebar {
    right: 0;
}

#sidenav-btn-check:checked ~ #sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 75;
  will-change: opacity;
  transition: all 0.5s;
}

</style>
