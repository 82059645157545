import { createRouter, createWebHashHistory } from 'vue-router'
import TopView from '../views/Top.vue'

const routes = [
  {
    path: '/',
    name: 'TopView',
    component: TopView,
    meta: {
      title: 'Top',
      desc: '町田市を拠点に活動しているフリーランスエンジニアです。システム開発、WEBサイト構築、運用保守、業務改善やシステム導入等、幅広くお手伝いできます。',
      keyword: '東京,東京都,町田,町田市,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "Top",
      og_type: "website",
      og_title: "Top",
      og_url: "https://www.nomadsystem.works/",
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'About',
      desc: '町田市を拠点に活動しているフリーランスエンジニアです。システム開発、WEBサイト構築、運用保守、業務改善やシステム導入等、幅広くお手伝いできます。',
      keyword: '東京,東京都,町田,町田市,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "About",
      og_type: "website",
      og_title: "About",
    }
  },
  {
    path: '/service',
    name: 'ServiceView',
    component: () => import('../views/Service.vue'),
    meta: {
      title: 'Service',
      desc: '町田市を拠点に活動しているフリーランスエンジニアです。システム開発、WEBサイト構築、運用保守、業務改善やシステム導入等、幅広くお手伝いできます。',
      keyword: '東京,東京都,町田,町田市,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "Service",
      og_type: "website",
      og_title: "Service",
    }
  },
  {
    path: '/skills',
    name: 'SkillsView',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'Skill',
      desc: '町田市を拠点に活動しているフリーランスエンジニアです。システム開発、WEBサイト構築、運用保守、業務改善やシステム導入等、幅広くお手伝いできます。',
      keyword: '東京,東京都,町田,町田市,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "Skill",
      og_type: "website",
      og_title: "Skill",
    }
  },
  {
    path: '/blog',
    name: 'BlogView',
    beforeEnter() {
      window.location = "https://blog.nomadsystem.works/"
    },
    meta: {
      title: 'Blog',
      desc: '町田市を拠点に活動しているフリーランスエンジニアです。システム開発、WEBサイト構築、運用保守、業務改善やシステム導入等、幅広くお手伝いできます。',
      keyword: '東京,東京都,町田,町田市,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "Blog",
      og_type: "website",
      og_title: "Blog",
    }
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: 'Contact',
      desc: '町田市を拠点に活動しているフリーランスエンジニアです。システム開発、WEBサイト構築、運用保守、業務改善やシステム導入等、幅広くお手伝いできます。',
      keyword: '東京,東京都,町田,町田市,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "Contact",
      og_type: "website",
      og_title: "Contact",
    }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicyView',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      title: 'PrivacyPolicy',
      desc: '町田市を拠点に活動しているフリーランスエンジニアです。システム開発、WEBサイト構築、運用保守、業務改善やシステム導入等、幅広くお手伝いできます。',
      keyword: '東京,東京都,町田,町田市,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "PrivacyPolicy",
      og_type: "website",
      og_title: "PrivacyPolicy",
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
