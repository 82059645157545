<template>
  <div class="sidebar">
    <ul v-on:click="uncheckSidenavBtn">
      <li><router-link to="/">Top</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <!--<li><router-link to="/service">Service</router-link></li>-->
      <li><router-link to="/blog">Blog</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
      <li><router-link to="/privacy">PrivacyPolicy</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideNavItem',
  setup() {
    function uncheckSidenavBtn() {
      var checkbox = document.getElementById('sidenav-btn-check');
      checkbox.checked = false;
    }

    return {
      uncheckSidenavBtn
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sidebar {
    width: 300px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 80;
    background-color: #2c3e50;
    transition: all 0.5s;
}
.sidebar ul {
    padding: 70px 10px 0;
}
.sidebar ul li {
    border-bottom: solid 1px #ffffff;
    list-style: none;
}
.sidebar ul li a {
    display: block;
    width: 100%;
    font-size: 15px;
    box-sizing: border-box;
    color:#ffffff;
    text-decoration: none;
    padding: 9px 15px 10px 0;
    position: relative;
}
.sidebar ul li a::before {
    content: "";
    width: 7px;
    height: 7px;
    border-top: solid 2px #ffffff;
    border-right: solid 2px #ffffff;
    transform: rotate(45deg);
    position: absolute;
    right: 11px;
    top: 16px;
}
</style>
