<template>
  <ul class="navbar">
    <li><router-link to="/">Top</router-link></li>
    <li><router-link to="/about">About</router-link></li>
    <!--<li><router-link to="/service">Service</router-link></li>-->
    <li><router-link to="/blog">Blog</router-link></li>
    <li><router-link to="/contact">Contact</router-link></li>
    <li><router-link to="/privacy">PrivacyPolicy</router-link></li>
  </ul>
</template>

<script>
export default {
  name: 'NavItem'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  float: right;
}

li {
  float: left;
}
</style>
