<template>
  <div class='container'>
    <Header />

    <router-view v-slot="{ Component }">
      <transition name="bounce" mode="out-in">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>

    <Footer />
  </div>
</template>

<script>
import Header from './views/common/Header.vue'
import Footer from './views/common/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  mounted : function(){
      var routeInstance = this.$route;
      this.createTitleDesc(routeInstance);
  },
  methods : {
    createTitleDesc : function(routeInstance){
      //titleを設定する処理
      if (routeInstance.meta.title) {
          var setTitle = routeInstance.meta.title + ' | ノマドシステム工房';
          document.title = setTitle;
      } else {
          document.title = 'ノマドシステム工房';
      }

      // メタタグのdescription設定処理
      if (routeInstance.meta.desc) {
          var setDesc = routeInstance.meta.desc + ' | ノマドシステム工房';
          document.querySelector("meta[name='description']").setAttribute('content', setDesc);
      } else {
          document.querySelector("meta[name='description']").setAttribute('content', '');
      }

      // メタタグのkeyword設定処理
      if (routeInstance.meta.keyword) {
          var setKeyword = routeInstance.meta.keyword;
          document.querySelector("meta[name='keywords']").setAttribute('content', setKeyword);
      } else {
          document.querySelector("meta[name='keywords']").setAttribute('content', '');
      }

      // メタタグのog:locale設定処理
      if (routeInstance.meta.og_locale) {
          var setOgLocale = routeInstance.meta.og_locale;
          document.querySelector("meta[property='og:locale']").setAttribute('content', setOgLocale);
      } else {
          document.querySelector("meta[property='og:locale']").setAttribute('content', '');
      }

      // メタタグのog:site_name設定処理
      if (routeInstance.meta.og_site_name) {
          var setOgSiteName = routeInstance.meta.og_site_name + ' | ノマドシステム工房';
          document.querySelector("meta[property='og:site_name']").setAttribute('content', setOgSiteName);
      } else {
          document.querySelector("meta[property='og:site_name']").setAttribute('content', '');
      }

      // メタタグのog:type設定処理
      if (routeInstance.meta.og_type) {
          var setOgType = routeInstance.meta.og_type;
          document.querySelector("meta[property='og:type']").setAttribute('content', setOgType);
      } else {
          document.querySelector("meta[property='og:type']").setAttribute('content', '');
      }

      // メタタグのog:title設定処理
      if (routeInstance.meta.og_title) {
          var setOgTitle = routeInstance.meta.og_title + ' | ノマドシステム工房';
          document.querySelector("meta[property='og:title']").setAttribute('content', setOgTitle);
      } else {
          document.querySelector("meta[property='og:title']").setAttribute('content', '');
      }

      // メタタグのkeyword設定処理
      if (routeInstance.meta.og_url) {
          var setOgUrl = routeInstance.meta.og_url;
          document.querySelector("meta[property='og:url']").setAttribute('content', setOgUrl);
      } else {
          document.querySelector("meta[property='og:url']").setAttribute('content', '');
      }
    }
  },
  watch: { 
    '$route' (routeInstance) {
        this.createTitleDesc(routeInstance);
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap');

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

body {
  background-image: url('@/assets/b029.gif');
  background-repeat: repeat;
  background-attachment: fixed;
}

#app {
  font-family: 'Kosugi Maru', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 1.5em;
  margin-top: 10px;
  
}

.container {
  display: grid!important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 80px auto 20px;
  grid-template-areas:
        "header header header header"
        "content content content content"
        "footer footer footer footer";
}

.header {
  grid-area: header;
  width: 70%;
  margin: 0 auto;
}

.content {
  grid-area: content;
  width: 100%;
  margin: 0 auto;
}

.footer {
  grid-area: footer;
  width: 100%;
  margin: 0 auto;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 0 10px;
}

a {
  color: #2b5366;
}
</style>
